/* header css */
.navbar-toggler {
  border: none;
}
.toggle_icon {
  height: 40px;
  width: 40px;
  border: none;
}
.toggle_icon2 {
  height: 30px;
  width: 30px;
  border: none;
}
.toggle_icon3 {
  height: 25px;
  width: 25px;
  margin: 3px;
  border: none;
}
.navbar-brand img {
  height: 45px;
  width: 100px;
}
.header-bg {
  background-color: #005c54;
}

a:hover {
  color: white !important;
}

::selection {
  color: rgb(223, 223, 223);
  background: #005c54;
}

.navbar {
  padding: 0.5px 1px;
}
.navbar-brand img {
  height: 45px;
  width: 100px;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff4a3;
  text-align: center;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(219, 219, 219);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: white;
}
/* end of header */

/* footer css */

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #056a77;
  color: white;
  text-align: center;
}
.footerdown {
  background-color: #0a1538;
  height: max-content;
}
.footer-link {
  color: white;
}
.footer-link:hover {
  color: gold;
  text-decoration: none;
}
a {
  color: inherit;
  cursor: pointer;
}

/* end of footer css */

/* Section One */
.homevideo {
  width: 100%;
  height: 200px;
  margin-top: 20px;
}
/* end of section one */

/* section four */
.middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* width: 100%; */
  text-align: center;
}
.btn {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #f1f1f1;
  margin: 20px;
  border-radius: 30%;
  box-shadow: 0 5px 15px -5px #00000070;
  color: black;
  overflow: hidden;
  position: relative;
}
.btn i {
  line-height: 40px;
  font-size: 26px;
  transition: 0.2s linear;
}
.btn:hover i {
  transform: scale(1.3);
  color: #f1f1f1;
}
.bgcolor1::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: #4267b2;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.bgcolor2::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: #1da1f2;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.bgcolor3::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.bgcolor4::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: rgb(236, 48, 48);
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.bgcolor5::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: #25d366;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.btn:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}
@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}
.social_icon {
  padding: 20px;
  transform: translateY(-50%);
  margin-top: 70px;
}
/* end of section four  */

/* section three */
.exercise_box {
  background-color: rgb(152, 177, 94);
  color: white;
  height: 150px;
  width: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exercise_box:hover {
  color: white;
}
.exercise {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  position: relative;
  width: 200px;
  height: 200px;
  /* background: #0f0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.exerciesandquizzes {
  color: #ffffff;
}
.exercise-heading-color {
  color: white;
}
/* end of section three  */

/* section five */

.course_bg {
  background-color: #282a35;
}
.html_bg {
  background-color: #d9eee1;
  padding: 10px;
}
.css_bg {
  background-color: #fff4a3;
  padding: 10px;
}
.card_button {
  font-size: 18px;
  text-decoration: none;
  background-color: #282a35;
  color: white;
  margin: auto;
  display: block;
  width: 200px;
  border: none;
  border-radius: 25px;
}
.card_button:hover {
  text-decoration: none;
  background-color: #0a1538;
}

@media screen and (max-width: 767px) {
  .html_bg {
    margin-bottom: 6px;
  }
}

/* end of section Five  */

/* sidebar */
.sidebar_bg_deisgn {
  background-color: #000000;
  padding-inline-start: 10px;
  margin-bottom: 3px;
}
.vertical-menu {
  width: 100%;
  height: 500px;
  overflow: scroll;
  padding: 0;
}

.vertical-menu a {
  background-color: #4a5044;
  color: lightgoldenrodyellow;
  display: block;
  padding: 5px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #009999;
  text-decoration: none;
}

.vertical-menu a.active {
  background-color: #030303;
  color: white;
}

@media screen and (min-width: 767px) {
  #mysidebar {
    display: initial;
  }
  .sidebar_bg_deisgn {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .navbar-toggler {
    display: none;
  }
  .sidebar_bg_deisgn {
    display: none;
  }
}

/* end sidebar css */

/* content area */
.programname {
  background-color: white;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
  padding: 0;
}
.contentdiv {
  border: 8px solid #bdd1b4;
  padding: 0.1%;
}
.page-link {
  background: #567956;
  width: 85px;
  border-radius: 5px;
  color: white;
  margin-top: 6px;
  text-align: center;
}
.page-link:hover {
  background: #1b1a1a;
  color: black;
  text-decoration: none;
}

.page-item1 {
  margin-right: auto;
}
.outputtitle {
  position: relative;
  top: 35px;
  color: white;
  margin-left: 10px;
}
.outputdisplay {
  border-top: 40px solid #82a07a;
  border-bottom: 10px solid #82a078;
  border-left: 10px solid #82a07a;
  border-right: 10px solid #82a07a;
  height: auto;
  background-color: rgb(23, 97, 82);
  border-radius: 4px;
  color: white;
  margin-bottom: 10px;
}
.description {
  border-top: 40px solid #82a07a;
  border-bottom: 10px solid #82a078;
  border-left: 10px solid #82a07a;
  border-right: 10px solid #82a07a;
  height: auto;
  background-color: rgb(23, 97, 82);
  border-radius: 4px;
  color: white;
  margin-bottom: 10px;
}

.language_name {
  background-color: rgb(15, 119, 83);
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 5px;
  font-weight: bold;
}

/* end of content area  */
